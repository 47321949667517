import { Link } from 'react-router-dom';
import './AboutMe.css';

function AboutMe() {
  return (
    <div>
      <header className="header-links">
        <nav>
          <Link to="/">HOME</Link>
          <Link to="/about">About Me</Link>
          <Link to="/projects">Projects</Link>
          <div id="indicator"></div>
        </nav>
      </header>

      <div className="aboutme">
        <div className="text-container">
          <h1>Martin W Jørgensen</h1>
          <p>
            I am studying for a bachelor's degree in informatics, currently in my sixth semester at the University of Oslo. I have a strong passion for algorithms, data structures, functional programming and enjoy challenging myself with omplex problem-solving.
          </p>

          <div className="text-subcontainer">
            <img src="/images/portrett.png" alt="Martin W Jørgensen" />
            <p>Besides my studies, I am actively involved in the student community. I currently serve as the vice leader of MAPS (Mathematics, Algorithms, and Programming for Students).</p>
            <p>I work part-time in the IT department at the Institute of Informatics at the University of Oslo. Recently, I was also a Scientific Assistant for the Language Technology group, where I annotated data to train large language models (LLMs). Additionally, I work as a researcher for Search People, assisting in identifying optimal candidates for various job positions.</p>         
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
